<template>
<SuperSignalsPage
    :symbols="SYMBOLS"
    :active-symbol="activeSymbol"
    :data="SUPPER_SIGNALS"
    :loader="GET_LOADER"
    :headers="SUPPER_HEADERS"
    :total-pages="SUPPER_SIGNALS_TOTAL_PAGES"
    :active-page="page"
    @switch-symbol="onSwitch"
    @changePage="changePage"
/>
</template>

<script>
import SuperSignalsPage from "@/components/Pages/SuperSignalsPage";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "SuperSignals",
  components: {SuperSignalsPage},
  computed: {
    ...mapGetters([
      'GET_LOADER',
      'SYMBOLS',
      'SUPPER_SIGNALS',
      'SUPPER_HEADERS',
      'SUPPER_SIGNALS_TOTAL_PAGES',
    ]),
  },
  data: () => ({
    activeSymbol: null,
    page: 1
  }),
  methods: {
    ...mapActions([
      'getSYMBOLS',
      'getSUPPER_SIGNALS',
      'SET_LOADER',
      "setREFRESH",
      "clearREFRESH"
    ]),
    changePage(page) {
      this.page = page
      this.refreshSignal()
    },
    onSwitch({ symbol, offset }) {
      if (symbol) this.activeSymbol = symbol.value
      if (offset) this.activeOffset = offset.value
      this.refreshSignal()
    },
    async refreshSignal() {
      this.SET_LOADER(true)
      await this.getSUPPER_SIGNALS({symbol: this.activeSymbol, page: this.page})
      await this.setREFRESH(this.refreshSignal)
      this.SET_LOADER(false)
    },
  },
  async mounted() {
    this.SET_LOADER(true)
    await this.getSYMBOLS()
    this.activeSymbol = this.SYMBOLS[0].id

    await this.refreshSignal()
  },
  destroyed() {
    this.clearREFRESH()
  },
}
</script>
