<template>
  <div class="dashboard">
    <div class="dashboard__menu">
      <menu-button :menu-list="symbolsList" :active="activeSymbol" @switch="symbolSwitch" is-changeable-header/>
    </div>
    <transition name="fade" mode="in-out">
      <div v-if="!data.length">
        <p class="text_center">Нет данных</p>
      </div>
      <div v-if="!loader && data" :class="['dashboard__table']">
        <table>
          <thead>
          <tr>
            <th v-for="(title) in headers" :key="title.title" :class="[title.bgColor,{'stars': title.title.toString().toLowerCase().includes('сигнал')}]">
              {{title.title}}
              <template v-if="title.title.toString().toLowerCase().includes('сигнал')">
                <svg v-for="i in 3" :key="`star${i}`" xmlns="http://www.w3.org/2000/svg" version="1.0" width="12pt" height="12pt" viewBox="0 0 1280.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
                  <metadata>
                    Created by potrace 1.15, written by Peter Selinger 2001-2017
                  </metadata>
                  <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="yellow" stroke="none">
                    <path d="M6280 10823 c-79 -247 -334 -1037 -566 -1758 l-422 -1310 -73 -1 c-41 -1 -654 -7 -1364 -14 -710 -6 -1296 -13 -1303 -16 -7 -2 -205 -4 -440 -4 -235 0 -502 -3 -593 -7 l-167 -6 1262 -939 c693 -516 1406 -1046 1583 -1178 l322 -239 -19 -58 c-62 -185 -1049 -3257 -1047 -3259 1 -1 133 90 292 201 160 111 838 582 1507 1047 l1217 846 433 -307 c238 -169 921 -653 1518 -1075 597 -423 1092 -775 1101 -782 8 -8 18 -14 21 -14 2 0 -91 278 -208 618 -118 339 -232 669 -253 732 -636 1842 -781 2266 -778 2273 2 8 290 204 2149 1464 510 345 927 632 928 637 0 5 -82 6 -187 2 -104 -3 -919 -9 -1813 -12 l-1625 -6 -177 483 c-97 266 -359 984 -583 1594 -224 611 -441 1206 -483 1322 -42 117 -79 213 -82 213 -3 0 -71 -201 -150 -447z"/>
                  </g>
                </svg>
              </template>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in data" :key="row.id">
            <template v-for="(cell, key) in headers">
              <td :key="key+row.id"
                  :class="setClass(row, key)"
              >
                {{ setValue(row, key) }}
              </td>
            </template>
          </tr>
          </tbody>
        </table>
      </div>
    </transition>
    <div v-if="totalPages > 1" class="stick-pagination">
      <pagination :pages="totalPages" :active="activePage" @changePage="$emit('changePage', $event)"/>
    </div>
  </div>
</template>

<script>
import MenuButton from "@/components/Blocks/MenuButton/MenuButton";
import Pagination from "@/components/Blocks/Pagination/Pagination";
export default {
  name: "SuperSignalsPage",
  components: {Pagination, MenuButton},
  props: ['symbols', 'activeSymbol', 'data', 'loader', 'headers', 'totalPages', 'activePage',],
  computed: {
    symbolsList() {
      return this.symbols ? this.symbols.map(el => {
        return {
          name: el.symbol,
          value: el.id
        }
      }) : []
    },
  },
  methods: {
    setClass(row, key) {
      const Class = []
      switch (key) {
        case 'date' :
        case 'time' :
        case 'close_price' :
        case 'day_change' :
          Class.push('text-left');
          break
        default :
          Class.push('text-center');
      }
      switch (key) {
        case 'date' :
        case 'time' :
        case 'close_price' :
        case 'day_change' :
        case 'zone' :
        case 'f4' :
        case 'tf_sum' :
          Class.push('bold-edge');
          break
      }
      if (row[key] !== null) {
        switch (key) {
          case 'date' :
          case 'time' :
            Class.push('dark-water-bg');
            break;
          case 'signal_number' :
            if (row[key].toString().toLowerCase().includes('super')) {
              Class.push('gold');
              Class.push('water-bg')
            }
            if (row[key].includes('LN')) Class.push('green-bg');
            if (row[key].includes('SH')) Class.push('red-bg');
            break
          case 'zone' :
          case 'sf1' :
          case 'sf2' :
          case 'sf3' :
          case 'sf4' :
            if (row[key].toString().toLowerCase().includes('short')) Class.push('red');
            if (row[key].toString().toLowerCase().includes('long')) Class.push('green');
            break
          case 'f1' :
          case 'f2' :
          case 'f3' :
          case 'f4' :
          case 'tf_sum' :
          case 'day_change' :
            if (row[key] > 0) Class.push('green');
            if (row[key] < 0) Class.push('red');
            break
        }
      }
      return Class
    },
    setValue(row, key) {
      if (row[key]!==null) {
        switch (key) {
          case 'close_price' :
            return `$ ${row[key].toLocaleString("ru-RU", {
              useGrouping: true,
            })}`
          case 'day_change' :
            return `${row[key].toLocaleString("ru-RU", {
              useGrouping: true,
            })}%`
          default : return row[key]
        }
      } return row[key]
    },
    symbolSwitch(symbol) {
      this.$emit('switch-symbol', { symbol: symbol })
    },
  }
}
</script>
<style lang="scss">
.dashboard {
  margin-top: 147px;
  margin-bottom: 15px;
  overflow-y: scroll;
  height: calc(100% - 162px);

  &::-webkit-scrollbar {
    width: 0;
  }

  &__menu {
    background-color: $grayscale-dark;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    position: absolute;
    left: 0;
    right: 0;
    top: 83px;
    border-bottom: 1px solid $grayscale-middle-light;
  }
  &__table {
    border: 2px solid #5B5C67;
    box-sizing: border-box;
    border-radius: 15px;
    overflow: hidden;
    //display: flex;
    //column-gap: 24px;
    width: max-content;
    &:last-child {
      margin-bottom: 27px;
    }

    & table:not(.area) {
      & tbody {
        & tr {
          &:nth-child(odd) {
            & td {
              background-color: $grayscale-dark;
              &:after {
                content: '';
                opacity: 0.3;
              }
            }
          }

          &:nth-child(even) {
            & td {
              background-color: $grayscale-middle;
              &:after {
                content: '';
                opacity: 0.4;
              }
            }
          }

          &:hover {
            & td {
              background-color: $grayscale-light;
              &:after {
                content: '';
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    & table {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.0125em;

      &::-webkit-scrollbar {
        display: none;
      }

      & thead {
        background-color: $grayscale-middle;

        & th {
          padding: 5px 3px;
          text-align: center;
          border-bottom: 2px solid $grayscale-extra-light;
          border-right: 2px solid $grayscale-extra-light;
          min-width: 44px;

          &.stars {
            display: flex;
          }

          &:last-child {
            border-right: none;
          }
        }

        & .sub-header {
          & th {
            padding: 5px 0;
          }

          & .k {
            &-mode {
              color: $blue-middle;
              background-color: #00ACEE33;
            }
          }

          & .d {
            &-mode {
              color: $yellow-middle;
              background-color: #FFAB2D33;
            }
          }

          & .o {
            &-mode {
              color: $white;
              background-color: #E28C0B1A;

            }
          }
        }

        & .group {
          border-right: 2px solid $grayscale-extra-light;

          &:last-child {
            border-right: 0;
          }

          &.title {
            text-align: center;
            padding-left: 0;
          }

          &.yellow {
            color: $yellow-middle;
          }
        }
      }

      & tbody {
        & tr {
          transition: all 300ms;

          & th {
            text-align: center;
            border-right: 2px solid $grayscale-extra-light;
          }

          & td {
            padding: 5px 6px;
            box-sizing: border-box;
            height: 26px;
            border-right: 1px solid $grayscale-light;
            border-bottom: 1px solid $grayscale-light;
            &.bold-edge {
              border-right: 2px solid $grayscale-extra-light;
              &:last-child {
                border-right: none;
              }
            }
            &:last-child {
              border-right: none;
            }
          }

          &:last-child {
            & td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
</style>
